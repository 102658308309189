import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { TwitterTimelineEmbed } from "react-twitter-embed"
import netlifyIdentity from "netlify-identity-widget"

import Layout, { Section } from "../components/Layout"
import { Instagram, Twitter } from "../components/Social"
import Features from "../components/Features"
import PageImage from "../components/PageUnsplashImage"
import Logo from "../components/Logo"
import { NextEvent } from "../components/Events"

export const IndexPageTemplate = ({
  image,
  imageCredit,
  title,
  subheading,
  mainpitch,
  banner,
  description,
  intro,
  nextEvent,
  ...props
}) => {
  useEffect(() => {
    if (typeof document === "object") {
      netlifyIdentity.init()
    }
  }, [])

  return (
    <div className="index">
      <PageImage src={image} credit={imageCredit}>
        <div
          style={{
            display: "flex",
            lineHeight: "1",
            justifyContent: "space-around",
            alignItems: "left",
            flexDirection: "column",
          }}>
          <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
            {title}
          </h1>
          <h3 className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
            {subheading}
          </h3>
        </div>
      </PageImage>

      <NextEvent event={nextEvent} />

      <Section>
        {(banner || "").length > 0 && (
          <div
            className="banner notification is-info is-text-center has-border"
            dangerouslySetInnerHTML={{ __html: banner }}
          />
        )}
        <div className="columns">
          <div className="column is-8">
            <div className="content negative-margin">
              <div className="wrapper">
                <div className="tile">
                  <h3 className="subtitle center">{mainpitch.description}</h3>
                </div>
                <div className="youtube-iframe-container">
                  <div>
                    <iframe
                      className="youtube-iframe"
                      src="https://www.youtube.com/embed/GcH9Hw_Re7g"
                      title="Apresentação - Instituto de Espiritualidade e Saúde"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    />
                  </div>
                </div>
              </div>
              <div className="columns">
                <div
                  className="description column is-12"
                  dangerouslySetInnerHTML={{ __html: description }}
                  style={{
                    lineHeight: "1.6",
                    marginTop: "1rem",
                  }}></div>
              </div>
              <Features gridItems={intro.blurbs} />
            </div>
          </div>
          <div className="column is-4 twitter-feed" style={{ background: "smokewhite" }}>
            <iframe
              title="IES Podcast"
              src="https://open.spotify.com/embed-podcast/show/48TvSs1yOJG8qpDbE9mh4W"
              width="100%"
              height="232"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            />
            {/*<TwitterTimelineEmbed autoHeight sourceType="profile" screenName="iesaude" />*/}
            <div style={{ paddingTop: "2rem", textAlign: "center" }} className="small-icons">
              Veja nossa programação nas redes sociais!
              <div className="social">
                <Instagram />
                <Twitter />
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  )
}

const IndexPage = ({ data, ...props }) => {
  const { frontmatter } = data.markdownRemark
  const monthlyEvents = data.monthlyEvents.list

  // Filter events client-side
  const today = new Date().toISOString().slice(0, 10)
  const upcomingEvents = monthlyEvents.filter(
    ({ node }) => node.frontmatter.date >= today
  )
  const nextEvent = upcomingEvents.length > 0 ? upcomingEvents[0].node : null

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        imageCredit={frontmatter.imageCredit}
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        banner={frontmatter.banner}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        nextEvent={nextEvent}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image
        imageCredit {
          author
          href
        }
        subheading
        banner
        mainpitch {
          description
        }
        description
        intro {
          blurbs {
            image
            text
            link
          }
        }
      }
    }

    monthlyEvents: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "monthly-event-page" }
        }
      }
    ) {
      list: edges {
        node {
          html
          id
          fields {
            slug
          }
          frontmatter {
            title
            callOut
            description
            notes
            date
            dateOverride
            startTime
            endTime
            form
            youtubeURL
            confirmed
            link
            linkText
            promoImage
          }
        }
      }
    }
  }
`
